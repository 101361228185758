<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import type { FormKitNode } from '@formkit/core'

const emailSent = ref(false)
const email = ref<string | undefined>(undefined)

const { t } = useI18n()

async function submitOtpRequest(values: { email: string }, node: FormKitNode) {
  email.value = values.email
  useState<string | undefined>('email').value = email.value
  umTrackEvent('send_email_button')

  const response = await useSupabaseClient().auth.signInWithOtp({
    email: values.email,
    options: { shouldCreateUser: false },
  })
  if (response.data && !response.error) {
    emailSent.value = true
    const route = useRoute()
    umTrackEvent('send_email_button_successful')
    await navigateTo(useLocalePath()({ path: '/otp', query: { ...route.query } }))
  }
  else if (response.error) {
    if (response.error.status === 422) {
      node.setErrors([t('sign_up.common.error_not_registered'), response.error.message])
      umTrackEvent('send_email_button_error_email_not_registered', { error: response.error.message })
    }
    else {
      node.setErrors([t('sign_up.common.error_generic'), response.error.message])
      umTrackEvent('send_email_button_error', { error: response.error.message })
    }
  }
  else {
    node.setErrors([t('sign_up.common.error_generic')])
    umTrackEvent('send_email_button_generic_error')
  }
}
</script>

<template>
  <div class="flex flex-col p-8 grow">
    
<FormKitLazyProvider config-file="true">
<h1 class="mb-4">
      {{ $t('login.login.headline') }}
    </h1>
    <p class="mb-2">
      {{ $t('login.login.explanation') }}
    </p>
    <FormKit type="form" :submit-label="$t('login.login.send_email')" @submit="submitOtpRequest">
      <FormKit type="email" name="email" :label="$t('sign_up.common.email.label')" :placeholder="$t('sign_up.common.email.placeholder')" validation="required|email" />
    </FormKit>
</FormKitLazyProvider>

  </div>
</template>
